"use client";

import { useTranslations } from "next-intl";

import { Button, FullscreenErrorMessage } from "~/components";
import { Link } from "~/navigation";

export default function NotFound() {
  const t = useTranslations();

  return (
    <FullscreenErrorMessage
      title={t("layout.notFound.title")}
      description={t("layout.notFound.description")}
    >
      <Link href="/" className="flex justify-center">
        <Button>{t("layout.notFound.homeLink")}</Button>
      </Link>
    </FullscreenErrorMessage>
  );
}
